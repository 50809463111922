/*global  ajax_object*/
/*global  ajaxUrl*/
/*global  google*/
/*global  initialActivities*/

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function initSlick() {
  if ($(window).width() <= 640) {
    if (!$('.activities-list').hasClass('slick-initialized')) {
      $('.activities-list').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.activities-slider-prev'),
        nextArrow: $('.activities-slider-next'),
      });
    }
  } else {
    if ($('.activities-list').hasClass('slick-initialized')) {
      $('.activities-list').slick('unslick');
    }
  }

  if ($(window).width() <= 640) {
    if (!$('.hotels-list').hasClass('slick-initialized')) {
      $('.hotels-list').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.hotels-prev'),
        nextArrow: $('.hotels-next'),
      });
    }
  } else {
    if ($('.hotels-list').hasClass('slick-initialized')) {
      $('.hotels-list').slick('unslick');
    }
  }
}

function initSlickTabs() {
  if ($(window).width() <= 1023) {
    $(
      '.activities-tab-section .accordion .is-active.accordion-item .accordion-content .category-tab-posts .category-tab-posts__wrap .tab-posts'
    ).each(function () {
      // Get the current slider index or identifier
      const $this = $(this);
      const index = $this.attr('class').match(/tab-posts-(\d+)/); // Extract the number from class

      if (index) {
        const sliderIndex = index[1]; // Get the number from the matched class
        const prevArrow = `.itineraries-prev-${sliderIndex}`;
        const nextArrow = `.itineraries-next-${sliderIndex}`;

        if (!$this.hasClass('slick-initialized')) {
          $this.slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: $(prevArrow),
            nextArrow: $(nextArrow),
            rows: 0,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        }
      }
    });
  } else {
    $(
      '.activities-tab-section .accordion .accordion-content .category-tab-posts .category-tab-posts__wrap .tab-posts'
    ).each(function () {
      if ($(this).hasClass('slick-initialized')) {
        $(this).slick('unslick');
      }
    });
  }
}

function adjustTopBarHeight() {
  var vh = $(window).height();
  var topBarTopHeight = $('.top-bar__top').outerHeight();
  var searchHeight = $('.search').outerHeight();

  var newHeight = vh - topBarTopHeight - searchHeight;

  $('.header-menu.menu').css('height', newHeight + 'px');
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  var mainMenu = document.querySelector('.top-bar__top');
  // var mainMenuHeight = mainMenu.offsetHeight;
  // $('.top-bar').css('height', 100 + 'vh' - mainMenuHeight);
  adjustTopBarHeight();

  $('.has-dropdown a').click(function () {
    $('#menu-main-menu').addClass('show-submenu');
  });

  $('.has-dropdown a').on('touchstart', function () {
    $('#menu-main-menu').addClass('show-submenu');
  });

  $('.header-menu ').on('hide.zf.dropdownMenu', function () {
    $('#menu-main-menu').removeClass('show-submenu');
  });

  $('.search__input').on('focus', function () {
    $('.search__submit').addClass('focused');
  });
  $('.custom-menu-icon').click(function () {
    // console.log(width);
    $('.top-bar').removeClass('is-disable');
    $('.top-bar').addClass('is-active');
  });
  $('.close-button').click(function () {
    //$('.top-bar').css('left', '-' + (topBarWidth + 54) + 'px');
    $('.top-bar').removeClass('is-active');
    $('.top-bar').addClass('is-disable');
    $(mainMenu).removeClass('show-submenu');
  });

  // Initialize on document ready
  initSlick();
  initSlickTabs();

  $('.explore-slider__bg').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    // infinite: false,
    arrows: false,
    fade: false,
    speed: 400,
    cssEase: 'cubic-bezier(0.175, 0.885, 0.25, 1.1)',
    asNavFor: '.explore-slider__nav',
  });
  $('.explore-slider__nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 400,
    cssEase: 'cubic-bezier(0.175, 0.885, 0.25, 1.1)',
    // infinite: false,
    asNavFor: '.explore-slider__bg',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    prevArrow: $('.slider-prev'),
    nextArrow: $('.slider-next'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  var categoriesArray = [];
  $('.list-heading').hide();

  $('.category-list__item').on('click', function () {
    $('.list-heading').show();
    var selectedCategories = $(this).data('category');
    // console.log(selectedCategories);
    // console.log(categoriesHeadingArray);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_activities_heading',
        headingCategory: selectedCategories,
        // paged: 1,
      },
      success: function (response) {
        $('.list-heading').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $('.category-list__item').on('click', function () {
    $('.category-list-wrap').removeClass('category-list-wrap__open');

    $('.category-list__item').removeClass('active-category');
    $(this).addClass('active-category');

    var selectedCategories = $(this).data('category');

    if ($(this).hasClass('active-category')) {
      categoriesArray = [selectedCategories];
    }

    // console.log(categoriesArray);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_activities',
        category: selectedCategories,
        paged: 1,
      },
      beforeSend: function () {
        $('.activities-filter-section .cell:first-child .loader').show();
      },
      success: function (response) {
        $('.activities-list').html(response);
        $('.activities-list').show();
      },
      complete: function () {
        $('.activities-filter-section .cell:first-child .loader').hide();
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $(document).on('change', '.category-select', function () {
    // $('.category-select').on('change', function() {
    var category_id = $(this).val(); // Get selected category ID

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_posts_by_category', // The action that will call the PHP function
        category_id: category_id,
      },
      beforeSend: function () {
        $('.posts-wrapper').html('<p>Loading posts...</p>'); // You can show a loading message or spinner
      },
      success: function (response) {
        $('.posts-wrapper').html(response); // Insert the retrieved posts into the wrapper
      },
      error: function () {
        $('.posts-wrapper').html(
          '<p>There was an error. Please try again.</p>'
        );
      },
    });
  });

  $(document).on('click', '.clear-selection', function () {
    $('.category-list__item').removeClass('active-category');
    // console.log('test');
    $('.list-heading').hide();
    $('.activities-list').hide();
    categoriesArray = [];
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'clear_filter_activities',
      },
      success: function (response) {
        $('.activities-list').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $(document).on('click', '.activities-more-button', function () {
    let page = $(this).data('paged');
    let currButton = $('.more-button-wrap');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_activities',
        category: categoriesArray,
        paged: page,
      },
      beforeSend: function () {
        $('.activities-filter-section .cell:first-child .loader').show();
      },
      success: function (response) {
        currButton.remove();
        $('.activities-list').append(response);
      },
      complete: function () {
        $('.activities-filter-section .cell:first-child .loader').hide();
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });

  $('.itineraries-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $('.itineraries-prev'),
    nextArrow: $('.itineraries-next'),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $('.posts-slider-section').each(function () {
    var $this = $(this);
    var postsSliderSectionData = $this.data('posts-number');
    // console.log('test');
    $this.find('.posts-list').slick({
      infinite: true,
      slidesToShow: postsSliderSectionData,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $this.find('.posts-slider-prev'),
      nextArrow: $this.find('.posts-slider-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  $('.activity-level-post-list').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $('.slider-prev'),
    nextArrow: $('.slider-next'),
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });

  $('.activity-level-category-list__item').on('click', function () {
    if (!$(this).hasClass('active-category-level')) {
      // Remove active class from all items and add to the clicked item
      $('.activity-level-category-list__item').removeClass(
        'active-category-level'
      );
      $(this).addClass('active-category-level');

      // Get the data-category value of the clicked item
      let selectedCategoryLevel = $(this).data('category');
      // console.log(selectedCategoryLevel);

      // Filter the slides based on the selected category
      $('.activity-level-post-list')
        .slick('slickUnfilter')
        .slick('slickFilter', function () {
          let postCat = $(this).data('post-cat');
          return (
            postCat == selectedCategoryLevel || selectedCategoryLevel == 'all'
          );
        });
    } else {
      $('.activity-level-category-list__item').removeClass(
        'active-category-level'
      );
      $('.activity-level-post-list').slick('slickUnfilter');
    }
  });

  /**
   * Calendar events
   */
  function calendarEvents() {
    $('.mec-has-event').each(function () {
      // Get the data-mec-cell value from the current .mec-has-event element
      var mecCell = $(this).data('mec-cell');

      // Find the element with the ID equal to the data-mec-cell value
      var targetElement = $('#mec-calendar-events-sec-560-' + mecCell);

      if (targetElement.length) {
        // Check if .event-colors div exists, if not create it
        var eventColorsDiv = $(this).find('.event-colors');
        if (eventColorsDiv.length === 0) {
          eventColorsDiv = $('<div class="event-colors"></div>');
          $(this).append(eventColorsDiv);
        } else {
          eventColorsDiv.empty();
        }

        // Iterate over each .mec-event-article within the target element
        targetElement.find('.mec-event-article').each(function () {
          // Get the background color from the .event-color element
          var bgColor = $(this).find('.event-color').css('background-color');

          if (bgColor) {
            // Create a span element with the background color
            var colorSpan = $('<span></span>')
              .css('background-color', bgColor)
              .addClass('event-color');

            // Append the span element to the .event-colors div
            eventColorsDiv.append(colorSpan);
          }
        });
      }
    });
  }

  calendarEvents();

  const targetNode = document.querySelector('.mec-calendar-table');
  const config = { childList: true };
  const callback = function (mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        calendarEvents();
      }
    }
  };
  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);

  $('.itinerary-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
  });
  $('.mobile-itinerary-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
  });

  var itinerarySliderDots = $('.itinerary-slider .slick-dots').outerWidth();

  // console.log(itinerarySliderDots);

  // Select all the images in the itinerary gallery
  $('.slick-current .itinerary-gallery .image').hover(
    function () {
      $('.slick-current .itinerary-gallery .image').removeClass('show-caption');
      $(this).addClass('show-caption');
      // Get the hovered image's source
      var imgSrc = $(this).find('img').attr('src');
      // Update the .slide-left-col with the hovered image
      $('.slide-left-col .left-col-image').html(
        '<img src="' + imgSrc + '" alt="Hovered Image">'
      );
    },
    function () {
      // Optional: Clear the .slide-left-col content when hover ends
      // $('.slide-left-col').empty();
    }
  );

  function updateSliderStyles() {
    if ($(window).width() < 1024) {
      $('.itinerary-slider .slick-next').css(
        'right',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
      $('.itinerary-slider .slick-prev').css(
        'left',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
    } else {
      $('.itinerary-slider .slick-next').css(
        'right',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
      $('.itinerary-slider .slick-prev').css(
        'left',
        'calc(50% - ' + itinerarySliderDots + 'px)'
      );
    }
  }

  // Run the function on page load
  updateSliderStyles();

  // Run the function on window resize
  $(window).resize(function () {
    updateSliderStyles();
  });

  jQuery(document).on('gform_post_render', function () {
    jQuery('.gform_wrapper input[type=file]').on('change', function () {
      const fileName = jQuery(this).val().split('\\').pop();
      jQuery('label', jQuery(this).parent().parent()).html(fileName);
    });
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.things-to-do-list__item .item-content-wrap').matchHeight();
  $('.activities-list__item .item-title').matchHeight();
  $('.tab-posts .post-title').matchHeight();
  $('.featured-post .featured-post__text').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Play video by custom play button
   */
  const video = document.getElementById('video');
  const playButton = document.getElementById('playButton');

  if (playButton) {
    playButton.addEventListener('click', function () {
      video.play();
      this.style.display = 'none';
      video.setAttribute('controls', '');
    });
  }

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  resizeVideo();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  initSlick();
  initSlickTabs();
  adjustTopBarHeight();
});
$(window).on('click', function () {
  initSlickTabs();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

//Google Activities Map //

jQuery(document).ready(function ($) {
  let map;
  let markers = [];

  // Define the initMap function globally so it can be used as a callback
  window.initMap = function () {
    const mapElement = document.getElementById('activities-map');
    if (!mapElement) {
      console.error('Map element not found.');
      return;
    }

    map = new google.maps.Map(mapElement, {
      center: { lat: 28.5383, lng: -81.1792 },
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    loadInitialMarkers();
  };

  function loadInitialMarkers() {
    if (
      typeof initialActivities !== 'undefined' &&
      Array.isArray(initialActivities)
    ) {
      updateMapMarkers(initialActivities);
    } else {
      console.error('Activities data is not an array or not defined.');
    }
  }

  function centerMap() {
    const bounds = new google.maps.LatLngBounds();

    markers.forEach((marker) => {
      if (marker.getVisible()) {
        bounds.extend(marker.getPosition());
      }
    });

    if (markers.length === 1) {
      map.setCenter(bounds.getCenter());
    } else {
      map.fitBounds(bounds);
      google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if (map.getZoom() > 12) {
          map.setZoom(12);
        }
      });
    }
  }

  function updateMapMarkers(activities) {
    markers.forEach((marker) => marker.setMap(null));
    markers = [];

    if (activities.length > 0) {
      activities.forEach((activity) => {
        if (activity.lat && activity.lng) {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(activity.lat),
              lng: parseFloat(activity.lng),
            },
            map,
            title: activity.title,
          });

          const infowindow = new google.maps.InfoWindow();

          marker.addListener('click', () => {
            const content = createInfoWindowContent(activity);
            infowindow.setContent(content);
            infowindow.open(map, marker);
            // infowindow.close();
          });

          markers.push(marker);
        }
      });

      centerMap();
    } else {
      map.setCenter({ lat: 28.5383, lng: -81.1792 });
      map.setZoom(11);
    }
  }

  function createInfoWindowContent(activity) {
    return `
      <div class='map-popup'>
        <div class='map-popup__img'>
        ${activity.image || ''}
        </div>
        <h3 class='map-popup__tittle'>${activity.title}</h3>
      </div>
    `;
  }

  function updateMarkerVisibility(filteredActivities) {
    const filteredTitles = new Set(filteredActivities.map((a) => a.title));

    markers.forEach((marker) => {
      marker.setVisible(filteredTitles.has(marker.getTitle()));
    });

    centerMap();
  }

  $('#activities-map-filter').on('submit', async (e) => {
    e.preventDefault();

    const neighborhood = $('#neighborhood').val();
    const activityCategory = $('#activity_category').val();
    const activityIntensity = $('#activity_intensity').val();

    try {
      const response = await $.ajax({
        url: ajaxUrl,
        method: 'POST',
        data: {
          action: 'map_filter_activities',
          neighborhood,
          activity_category: activityCategory,
          activity_intensity: activityIntensity,
        },
      });

      if (response && Array.isArray(response.response)) {
        updateMarkerVisibility(response.response);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      if (error.responseJSON && error.responseJSON.data) {
        console.error('Server error:', error.responseJSON.data);
      } else {
        console.error('AJAX error:', error);
      }
    }
  });

  function loadScript() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCZTr7BH-BN0wAAsa1PbZJLdHRPVN6s3dk&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  // Ensure the map script is loaded after the DOM is ready
  loadScript();
});
jQuery(document).ready(function ($) {
  // Convert the PHP date to ISO 8601 format
  var newDate = $('#countdown').data('new-date'); // e.g., "29/09/2024"
  // console.log(newDate);
  // Split the date string and rearrange it to "YYYY-MM-DD"
  var parts = newDate.split('/');
  var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0]; // "2024-09-29"

  var countdownDate = new Date(formattedDate).getTime();
  // console.log(countdownDate);
  var $days = $('#days');
  var $hours = $('#hours');
  var $minutes = $('#minutes');
  var $seconds = $('#seconds');

  var countdown = setInterval(function () {
    var now = new Date().getTime();
    // console.log(now);
    var distance = countdownDate - now;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Update the countdown
    $days.text(days);
    $hours.text(hours);
    $minutes.text(minutes);
    $seconds.text(seconds);

    // Check if the countdown is over
    if (distance < 0) {
      clearInterval(countdown);
      $('#countdown').html('EXPIRED');
    }
  }, 1000);
});
